import {
  isBreakableAndInStock,
  productIsInStatus,
} from './product-summary.utils';
import { ProductAlertMessage } from '../models/product-alert-message';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import { productHasStatus } from '@usf/ngrx-product';
import { Product } from '@shared/models/product.model';
import { DIRECT_STAT2_PDP_OOS_MESSAGE } from '@product-detail/constants/constants';
import { daysInTheFuture, isCurrentDate } from './po-dates-util';
import { mslProductHasSecondaries } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { calculateMslAlert } from '@shared/selectors/helpers/msl-unrestrict-helper';

export const buildAlertMessage = (
  product: Product,
  isProductCard: boolean,
  isMslRestricted: boolean,
  isOGRestricted: boolean,
  isMSLRestrictionOverrideY?: boolean,
  isForceRegularOrderProduct?: boolean,
): ProductAlertMessage => {
  // CUSTOMER MSL RESTRICTED, PRODUCT NOT ORDERABLE ON MSL, PRODUCT NOT MSL SECONDARY:
  // PDP - "Unable to Order. This product is not on your Master Shopping List."
  // PRODUCT CARD - "Unable to Order. This product is not on your Master Shopping List."
  if (
    calculateMslAlert(
      isMSLRestrictionOverrideY,
      isMslRestricted,
      product?.mslProduct?.isOrderableOnMsl,
      mslProductHasSecondaries(product?.mslProduct),
      product?.orderable,
      product?.summary?.mslUnrestrict,
      product?.summary?.properties?.has(ProductPropertiesEnum.discontinued),
    )
  ) {
    return mslRestrictedAlertMessage();
  }
  // CUSTOMER OG RESTRICTED, PRODUCT NOT ON ORDER GUIDE:
  // PDP - "Unable to Order. This product is not on your Master Shopping List."
  // PRODUCT CARD - "Unable to Order. This product is not on your Master Shopping List."
  else if (
    isOGRestricted &&
    !product?.summary?.properties.has(ProductPropertiesEnum.onOrderGuide) &&
    !product?.orderable
  ) {
    return ogRestrictedAlertMessage();
  }
  // PRODUCT STATUS 8 AND NOT IN STOCK OR PRODUCT STATUS 9, HAS DISCONTINUED PROPERTY:
  // PDP - "This product has been discontinued. Please find a replacement."
  // PRODUCT CARD: "Discontinued"
  else if (
    product?.summary?.properties?.has(ProductPropertiesEnum.discontinued)
  ) {
    return discontinuedAlertMessage(isProductCard);
  }
  // PRODUCT STATUS 2, FIRST AVAILABLE SUB STAT 3 W/ INVENTORY:
  // PDP - "Temporarily unavailable from manufacturer."
  // PRODUCT CARD: Temporarily Unavailable from Manufacturer"
  else if (
    product?.summary?.properties?.has(
      ProductPropertiesEnum.supplierOutOfStock,
    ) &&
    !product?.summary?.properties?.has(ProductPropertiesEnum.direct) &&
    !isForceRegularOrderProduct &&
    !isBreakableAndInStock(product) &&
    !product?.hideOutOfStock
  ) {
    return outOfStockFromSupplierAlertMessage(isProductCard);
  }
  // PRODUCT STATUS 2, HAS DIRECT PROPERTY:
  // PDP - "Unavailable today. We are working to get more inventory."
  // PRODUCT CARD - "More Inventory Coming Soon"
  else if (
    product?.summary?.properties?.has(
      ProductPropertiesEnum.supplierOutOfStock,
    ) &&
    product?.summary?.properties?.has(ProductPropertiesEnum.direct)
  ) {
    return outOfStockFromSupplierAndDirectAlertMessage(isProductCard, product);
  }
  // HAS SPECIAL ORDER PROPERTY:
  // PDP - Call to order.
  // PRODUCT CARD - "Call to Order"
  else if (
    product?.summary?.properties?.has(ProductPropertiesEnum.specialOrder)
  ) {
    return specialOrderAlertMessage(isProductCard);
  }
  // PRODUCT STATUS 0 AND NOT IN STOCK:
  // "Out of Stock. Will Ship if Available."
  else if (
    productHasStatus('0', product?.inventory) &&
    !product?.inventory?.isInStock &&
    !isBreakableAndInStock(product) &&
    !product?.hideOutOfStock
  ) {
    return outOfStockWillShipIfAvailAlertMessage();
  }
  // PRODUCT STATUS 3 OR 4 AND NOT IN STOCK:
  // PDP - "Out of stock."
  // PRODUCT CARD - "Out of Stock"
  else if (
    (productIsInStatus(['3', '4'], product) &&
      !product?.inventory?.isInStock &&
      !isBreakableAndInStock(product) &&
      !product?.hideOutOfStock) ||
    (productHasStatus('2', product?.inventory) &&
      !product?.inventory?.isInStock &&
      !isBreakableAndInStock(product) &&
      !product?.hideOutOfStock &&
      !isForceRegularOrderProduct)
  ) {
    return outOfStockAlertMessage(isProductCard);
  }
  // NO MESSAGE TO DISPLAY
  else {
    return noDisplayableAlertMessage();
  }
};

const mslRestrictedAlertMessage = (): ProductAlertMessage => ({
  display: true,
  primaryText: 'i18n.lists.productCardMsgs.unableToOrder',
  secondaryText: 'i18n.lists.productCardMsgs.productNotOnMSL',
});

const ogRestrictedAlertMessage = (): ProductAlertMessage => ({
  display: true,
  primaryText: 'i18n.lists.productCardMsgs.unableToOrder',
  secondaryText: 'i18n.lists.productCardMsgs.productNotOnOG',
});

const outOfStockFromSupplierAlertMessage = (
  isProductCard: boolean,
): ProductAlertMessage => ({
  display: true,
  primaryText: isProductCard
    ? 'i18n.lists.productCardMsgs.tempUnavail'
    : 'i18n.lists.productCardMsgs.tempUnavailLowercase',
  secondaryText: '',
});

const outOfStockFromSupplierAndDirectAlertMessage = (
  isProductCard: boolean,
  product?: Product,
): ProductAlertMessage => {
  const { primaryText, secondaryText, secondaryTextDate, boldedText } =
    calculateNextInStockMessageForProduct(product, isProductCard);
  return {
    display: true,
    primaryText,
    secondaryText,
    secondaryTextDate,
    ...(boldedText && { boldedText }),
  };
};

const specialOrderAlertMessage = (
  isProductCard: boolean,
): ProductAlertMessage => ({
  display: true,
  primaryText: isProductCard
    ? 'i18n.lists.productCardMsgs.callToOrder'
    : 'i18n.lists.productCardMsgs.callToOrderSentenceCase',
  secondaryText: '',
});

const outOfStockWillShipIfAvailAlertMessage = (): ProductAlertMessage => ({
  display: true,
  primaryText: 'i18n.lists.productCardMsgs.outOfStock',
  secondaryText: 'i18n.lists.productCardMsgs.willShipIfAvail',
});

const outOfStockAlertMessage = (
  isProductCard: boolean,
): ProductAlertMessage => ({
  display: true,
  primaryText: isProductCard
    ? 'i18n.lists.productCardMsgs.outOfStock'
    : 'i18n.lists.productCardMsgs.outOfStockSentenceCase',
  secondaryText: '',
});

const discontinuedAlertMessage = (
  isProductCard: boolean,
): ProductAlertMessage => ({
  display: true,
  primaryText: isProductCard
    ? 'i18n.lists.productCardMsgs.discontinued'
    : 'i18n.lists.productCardMsgs.productDiscontinued',
  secondaryText: isProductCard
    ? ''
    : 'i18n.lists.productCardMsgs.findReplacement',
});

const noDisplayableAlertMessage = (): ProductAlertMessage => ({
  display: false,
  primaryText: '',
  secondaryText: '',
});

export const calculateNextInStockMessageForProduct = (
  product: Product,
  isProductCard: boolean,
): {
  primaryText: string;
  secondaryText: string;
  secondaryTextDate: string;
  boldedText: string;
} => {
  let primaryText = '';
  let secondaryText = '';
  let secondaryTextDate = '';
  let boldedText = '';
  const nextInStockDate = product?.summary?.poDates
    ? product?.summary?.poDates[0]
    : null;
  let timeAhead = daysInTheFuture(nextInStockDate);
  const poDateIsWithinValidTimeFrame = timeAhead > 0 && timeAhead <= 30;
  if (
    !!nextInStockDate &&
    !isProductCard &&
    isCurrentDate(nextInStockDate) &&
    !isProductCard
  ) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PDP_PO_DATE_AVAILABLE_MESSAGE;
    secondaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PDP_NEXT_RESTOCK_DATE_MESSAGE;
    boldedText = DIRECT_STAT2_PDP_OOS_MESSAGE.TODAY;
  } else if (
    !!nextInStockDate &&
    isProductCard &&
    isCurrentDate(nextInStockDate) &&
    isProductCard
  ) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_PRIMARY_MESSAGE;
    secondaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_PO_DATE_SECONDARY;
    boldedText = DIRECT_STAT2_PDP_OOS_MESSAGE.TODAY;
  } else if (
    !!nextInStockDate &&
    isProductCard &&
    poDateIsWithinValidTimeFrame
  ) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_PRIMARY_MESSAGE;
    secondaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_PO_DATE_SECONDARY;
    secondaryTextDate = nextInStockDate.formattedArrivalDate;
  } else if (!!nextInStockDate && !isProductCard) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PDP_PO_DATE_AVAILABLE_MESSAGE;
  } else if (
    (!nextInStockDate && isProductCard) ||
    (isProductCard && !poDateIsWithinValidTimeFrame)
  ) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_PRIMARY_MESSAGE;
    secondaryText =
      DIRECT_STAT2_PDP_OOS_MESSAGE.PRODUCT_CARD_NO_PO_DATE_SECONDARY;
  } else if (!nextInStockDate && !isProductCard) {
    primaryText = DIRECT_STAT2_PDP_OOS_MESSAGE.PDP_DEFAULT_MESSAGE;
  }
  return {
    primaryText,
    secondaryText,
    secondaryTextDate,
    boldedText,
  };
};
