import { Dictionary } from '@ngrx/entity';
import { PlatformEnum } from '@panamax/app-state';
import { PlatformType } from '@panamax/app-state/lib/models/platform.model';
import { SUBSTITUTE_TYPE } from '@product-detail/constants/constants';
import { ProductQuickViewWarning } from '@product-detail/models/product-quick-view-warning';
import { getProductAlternative } from '@product-detail/utils/product-alternative.util';
import { ListFlagsEnum } from '@shared/constants/sub-restrictions-enum';
import { ListItemProduct, RecentPurchaseState } from '@usf/ngrx-list';
import { MListItemProduct } from '@usf/ngrx-list/lib/reducers/master-list-item/master-list-item.reducer';
import { CartState, NextDeliveryDate, OrderItemState } from '@usf/ngrx-order';
import { ProductPricingDetailState } from '@usf/ngrx-pricing';
import {
  BetterBuysState,
  getPropertiesSet,
  isSupplierOutOfStock,
  LoadingState,
  MslProduct,
  NonUSFProductState,
  PRODUCT_ATTRIBUTE_TRACK,
  productHasStatus,
} from '@usf/ngrx-product';
import { IPromotionState } from '@usf/ngrx-promotions';
import {
  PoDate,
  ProductInventory,
  ProductPropertiesEnum,
  ProductScoop,
  ProductSummary,
} from '@usf/product-types';
import * as dayjs from 'dayjs';
import {
  getCESMessageLong,
  getCESMessageShort,
  getDirectMessage,
  getJITMessage,
  isBreakableAndInStock,
  productIsInStatus,
} from 'src/app/product-detail/utils/product-summary.utils';
import { hasRecentPurchase } from '../../../lists/pages/recently-purchased/selectors/helpers/recent-purchase.selectors.helper';
import { ImageVariantEnum } from '../../../product-detail/models/image-variant.enum';
import {
  ProductAlternative,
  ProductAlternativeTypeEnum,
} from '../../../product-detail/models/product-alternative';
import { ProductDrawerMessage } from '../../../product-detail/models/product-drawer-message';
import { extractPoDates } from '../../../product-detail/utils/po-dates-util';
import { buildAlertMessage } from '../../../product-detail/utils/product-alert-message.utils';
import {
  getFirstImageURL,
  getOrderImages,
} from '../../../product-detail/utils/product-images.util';
import { getDisplayTags } from '../../../product-detail/utils/product-tags-util';
import { ProductTypes } from '../../constants/product-type.enum';
import { AppStateForProduct } from '../../models/app-state-for-product';
import { ListDataForProduct } from '../../models/list-data-for-product';
import { Product } from '../../models/product.model';
import { BetterBuyProduct } from '../../models/better-buy-card.model';
import { HotKeys } from '../../constants/hot-key.enum';
import { ProductPriceUtil } from '@product-detail/utils/product-price.util';
import { Customer } from '@usf/customer-types';
import { GlPim } from '@usf/ngrx-gl';
import { GlDetails } from '../../../inventory/models/inventory-view.model';
import {
  calculateMslAlert,
  calculateMslRestriction,
} from './msl-unrestrict-helper';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';

export const checkElementWasFound = element => {
  if (
    element?.loadingState === LoadingState.notFound ||
    element?.loadingState === LoadingState.error
  ) {
    return null;
  }
  if (
    element?.loadingState === LoadingState.loaded ||
    element?.loadingState === LoadingState.slim
  ) {
    const { loadingState, ...result } = element;
    return result;
  }
  return undefined;
};

export const buildSummary = (
  product: Product,
  productScoops: Dictionary<ProductScoop>,
  currentDate = dayjs(),
): ProductSummary => {
  if (!product?.summary) return null;

  product.summary.properties = getPropertiesSet(
    product,
    productScoops,
    currentDate,
  );

  // Remove supplier out of stock if we still have remaining stock (cases on hand)
  if (
    product.summary.properties.has(ProductPropertiesEnum.supplierOutOfStock) &&
    product.inventory.isInStock &&
    product.inventory.casesOnHand > 0
  ) {
    product.summary.properties.delete(ProductPropertiesEnum.supplierOutOfStock);
  }

  return product.summary;
};

const hasAvailableInventory = (product: Product) => {
  return (
    product?.inventory?.available ||
    isBreakableAndInStock(product) ||
    product?.hideOutOfStock
  );
};

export const canOrderProduct = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
): boolean => {
  const isMslRestricted = appStateForProduct?.isMSLRestrictionOverrideY
    ? false
    : appStateForProduct?.isMslRestricted;

  return (
    !(
      !hasAvailableInventory(product) ||
      (isProductRestricted(product, appStateForProduct, isMslRestricted) &&
        !isProductAllowedDivisionSub(
          product,
          productsMap,
          appStateForProduct,
          listDataForProduct,
          isMslRestricted,
        )) ||
      product?.alternative?.prioritizeAlternative
    ) ||
    isForceRegularOrderProduct(product, appStateForProduct, isMslRestricted) ||
    handleStat34OutOfStock(product, appStateForProduct, isMslRestricted)
  );
};

export const isProductRestricted = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  isMslRestricted: boolean,
): boolean =>
  calculateMslRestriction(
    isMslRestricted,
    product?.mslProduct?.isOrderableOnMsl,
    mslProductHasSecondaries(product?.mslProduct),
    product?.summary?.mslUnrestrict ?? false,
  ) ||
  (appStateForProduct.isOgRestricted &&
    !product.summary.properties.has(ProductPropertiesEnum.onOrderGuide));

export const mslProductHasSecondaries = (mslProduct: MslProduct) => {
  let numberOfMslSecondaries = 0;
  if (mslProduct?.mslSecondaries) {
    numberOfMslSecondaries = Object.keys(mslProduct.mslSecondaries).length;
  }
  return numberOfMslSecondaries > 0;
};

export const isProductAllowedDivisionSub = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
  isMslRestricted: boolean,
): boolean => {
  // Unrestricted can order any sub
  if (
    !appStateForProduct.isMslRestricted &&
    !appStateForProduct.isOgRestricted
  ) {
    return true;
  }
  // If the product is not a sub for any products customer cannot order
  if (!product?.summary?.baseProducts) {
    return false;
  }

  let isOrderableSub = false;
  // If one of the base products is orderable but out of stock customer can order
  product.summary.baseProducts.forEach(productNumber => {
    const baseProduct = productsMap.get(productNumber);
    const alternative = getProductAlternative(
      baseProduct,
      productsMap,
      appStateForProduct,
      listDataForProduct,
    );

    if (
      !!baseProduct &&
      !isProductRestricted(baseProduct, appStateForProduct, isMslRestricted) &&
      alternative?.required
    ) {
      isOrderableSub = true;
    }
  });

  return isOrderableSub;
};

export const enrichProductWithAllowSearchForSubs = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  // if for some reason field is empty, assign most permissive case
  const subsCase = appStateForProduct.subsCase ?? ListFlagsEnum.CASE_N_Y;
  switch (subsCase) {
    // all msl restricted customers cannot search for subs
    // all customers not allowed subs/restricted to msl subs cannot search for subs
    case ListFlagsEnum.CASE_M_M:
    case ListFlagsEnum.CASE_M_N:
    case ListFlagsEnum.CASE_M_O:
    case ListFlagsEnum.CASE_M_Y:
    case ListFlagsEnum.CASE_Y_M:
    case ListFlagsEnum.CASE_N_N:
    case ListFlagsEnum.CASE_N_M:
      product.allowSearchForSubs = false;
      break;

    // allow search for subs for OG restricted customers
    // and non MSL restricted non sub restricted customers
    case ListFlagsEnum.CASE_Y_N:
    case ListFlagsEnum.CASE_Y_O:
    case ListFlagsEnum.CASE_Y_Y:
    case ListFlagsEnum.CASE_N_O:
    case ListFlagsEnum.CASE_N_Y:
    default:
      product.allowSearchForSubs = true;
      break;
  }
};

export const enrichProduct = (
  product: Product,
  productsMap: Map<number, Product>,
  pricingDict: Dictionary<ProductPricingDetailState>,
  orderItemState: OrderItemState,
  promotionState: IPromotionState,
  listDataForProduct: ListDataForProduct,
  appStateForProduct: AppStateForProduct,
  betterBuys: Dictionary<BetterBuysState>,
  selectedCustomer: Customer,
  isGuestUser: boolean,
  partnerState: PartnerState,
  nextDeliveryDate: NextDeliveryDate,
  cartState: CartState,
  prioritizeAlternative: boolean,
) => {
  if (product) {
    enrichProductWithPricingData(product, pricingDict);
    enrichProductWithListRelatedData(
      product,
      listDataForProduct,
      appStateForProduct,
    );
    enrichProductWithHideOutOfStock(
      product,
      appStateForProduct,
      selectedCustomer,
      partnerState,
      nextDeliveryDate,
      cartState,
      prioritizeAlternative,
    );
    enrichProductWithOrderableFlag(
      product,
      productsMap,
      appStateForProduct,
      listDataForProduct,
    );
    enrichProductWithProductType(product);
    enrichProductWithHotkeyIds(product);
    enrichProductWithMessages(product, appStateForProduct);
    enrichProductWithThumbnail(product);
    enrichProductWithTags(
      product,
      appStateForProduct.isMslRestricted,
      isGuestUser,
    );
    enrichProductWithHideQtyBoxes(product);
    enrichProductWithOrderData(product, orderItemState);
    enrichProductWithTrackingAttributes(product);
    enrichProductWithPromotionsData(product, promotionState);
    enrichProductWithDrawerMessage(product, appStateForProduct);
    enrichProductWithQuickViewWarning(product, appStateForProduct);
    enrichProductWithAllowSearchForSubs(product, appStateForProduct);
    enrichProductWithBetterBuy(
      product,
      appStateForProduct,
      listDataForProduct,
      productsMap,
      betterBuys,
    );
    enrichWithShowHideWorksWellWith(product, appStateForProduct, isGuestUser);
    enrichProductWithPricePerPortion(product, selectedCustomer);
  }
};

export const enrichProductAlternative = (
  product: Product,
  productsMap: Map<number, Product>,
  pricingDict: Dictionary<ProductPricingDetailState>,
  orderItemState: OrderItemState,
  promotionState: IPromotionState,
  listDataForProduct: ListDataForProduct,
  appStateForProduct: AppStateForProduct,
  selectedCustomer: Customer,
  isGuestUser: boolean,
) => {
  if (product) {
    product.hasAlternative = !ignoreSupplierOutOfStock(
      product,
      appStateForProduct.isForceRegularOrderProduct,
    );
    if (product?.alternative?.product) {
      enrichProductWithSupplierOutOfStock(product);
      enrichProductWithMessages(product, appStateForProduct);
      enrichProductWithSubstituteMessage(product, appStateForProduct);
      enrichProductWithPricingData(product?.alternative?.product, pricingDict);
      enrichProductWithListRelatedData(
        product?.alternative?.product,
        listDataForProduct,
        appStateForProduct,
      );
      enrichProductWithOrderableFlag(
        product,
        productsMap,
        appStateForProduct,
        listDataForProduct,
      );
      enrichProductWithProductType(product.alternative.product);
      enrichProductWithHotkeyIds(product);
      enrichProductAlternativeWithMessages(
        product?.alternative?.product,
        appStateForProduct.directAllowReturnsFlag,
      );
      enrichProductWithThumbnail(product?.alternative?.product);
      enrichProductWithTags(
        product?.alternative?.product,
        appStateForProduct.isMslRestricted,
        isGuestUser,
      );
      enrichProductWithOrderData(product?.alternative?.product, orderItemState);
      enrichProductWithTrackingAttributes(product?.alternative?.product);
      enrichProductWithPromotionsData(
        product?.alternative?.product,
        promotionState,
      );
    }
    enrichProductWithDrawerMessage(product, appStateForProduct);
    enrichProductWithQuickViewWarning(product, appStateForProduct);
    enrichProductWithAllowSearchForSubs(product, appStateForProduct);
    enrichProductWithPricePerPortion(product, selectedCustomer);
  }
};

export const enrichProductWithProductType = (product: Product) => {
  if (
    productHasStatus('0', product?.inventory) &&
    !product?.inventory?.isInStock
  ) {
    product.productType = ProductTypes.stat0OutOfStock;
  }
};

export const enrichProductWithPricingData = (
  product: Product,
  pricingDict: Dictionary<ProductPricingDetailState>,
) => {
  const price = pricingDict[product.productNumber];
  product.pricing = {
    ...price,
    loading: price?.loadingState === LoadingState.loading,
  };
};

export const enrichProductWithListRelatedData = (
  product: Product,
  listDataForProduct: ListDataForProduct,
  appStateForProduct: AppStateForProduct,
) => {
  enrichProductWithListData(
    product,
    listDataForProduct.listItemProductMap,
    appStateForProduct.isGlOwner,
  );
  enrichProductWithMasterListData(
    product,
    listDataForProduct.masterListItemProductMap,
    appStateForProduct.isMslRestricted,
  );
  enrichProductWithRecentPurchaseData(
    product,
    listDataForProduct.recentPurchaseDict,
  );
};

export const enrichProductWithListData = (
  product: Product,
  listItemProductMap: Dictionary<ListItemProduct>,
  isGLCustomer: boolean,
) => {
  let listItemProduct: ListItemProduct;
  if (listItemProductMap) {
    listItemProduct = listItemProductMap[product.productNumber];
    if (listItemProduct) {
      product.summary.properties.add(
        ProductPropertiesEnum.onOrderGuideOrOnShoppingList,
      );
      if (listItemProduct.isOnList) {
        product.summary.properties.add(ProductPropertiesEnum.onShoppingList);
      }
      if (listItemProduct.isOnOrderGuide) {
        product.summary.properties.add(ProductPropertiesEnum.onOrderGuide);
      }
      if (listItemProduct.customerProductNumber) {
        product.isGLCustomer = isGLCustomer;
        product.customerProductNumber = isGLCustomer
          ? 'GL #' + listItemProduct.customerProductNumber
          : 'Cust Prod #' + listItemProduct.customerProductNumber;
      }
      product.lists = listItemProduct.lists;
    }
  }
};

export const enrichProductWithMasterListData = (
  product: Product,
  masterListItemProductMap: Dictionary<MListItemProduct>,
  isMslRestricted: boolean,
) => {
  let masterListItemProduct: MListItemProduct;
  if (masterListItemProductMap) {
    masterListItemProduct = masterListItemProductMap[product.productNumber];
    if (masterListItemProduct) {
      product.masterLists = masterListItemProduct.masterLists ?? [];
      product.summary.properties.add(ProductPropertiesEnum.onMasterList);
    }
  }
  // Shouldnt have this duplicated across products. Will remove once refactor of quickview is done
  product.isMslRestricted = isMslRestricted;
};

export const enrichProductWithRecentPurchaseData = (
  product: Product,
  recentPurchaseDict: Dictionary<RecentPurchaseState>,
) => {
  if (hasRecentPurchase(recentPurchaseDict[product.productNumber])) {
    product.summary.properties.add(ProductPropertiesEnum.recentlyPurchased);
    product.recentPurchase = recentPurchaseDict[product.productNumber];
  }
};

export const enrichProductWithTrackingAttributes = (product: Product) => {
  product.trackingAttributes =
    mapProductPropertiesToAnalyticsTrackingProperties(
      product.summary.properties,
      undefined,
    );
};

export const enrichProductWithPromotionsData = (
  product: Product,
  promotionState: IPromotionState,
) => {
  const promotionDetails = promotionState.entities[product.productNumber];
  if (promotionDetails) {
    product.promotionDetails = promotionDetails;
    product.trackingAttributes += `,deal:${promotionDetails.promoId}`;
  }
};

export const enrichProductWithSupplierOutOfStock = (product: Product) => {
  if (shouldAddSupplierOutOfStock(product.inventory, product?.alternative)) {
    product.summary.properties.add(ProductPropertiesEnum.supplierOutOfStock);
  }
};

export const enrichProductWithHideOutOfStock = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  selectedCustomer: Customer,
  partnerState: PartnerState,
  nextDeliveryDate: NextDeliveryDate,
  cartState: CartState,
  prioritizeAlternative = false,
) => {
  product.summary.poDates = extractPoDates(product.summary?.poDates);

  product.hideOutOfStock = isHideOutOfStock(
    product,
    appStateForProduct,
    selectedCustomer,
    partnerState,
    nextDeliveryDate,
    cartState,
    extractPoDates(product?.summary?.poDates),
    prioritizeAlternative,
  );
};

export const isHideOutOfStock = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  selectedCustomer: Customer,
  partnerState: PartnerState,
  nextDeliveryDate: NextDeliveryDate,
  cartState: CartState,
  poDates: PoDate[],
  prioritizeAlternative = false,
) => {
  if (
    !product?.summary ||
    !product?.inventory ||
    appStateForProduct?.isHideInventoryOutOfStockYellowModule ||
    partnerState?.isPunchthru ||
    partnerState?.punchoutProfile?.punchout ||
    cartState?.orderHeader?.addOrderSource === 'PO' ||
    selectedCustomer?.autoSubProducts
  ) {
    return false;
  }

  const summary = product?.summary;
  if (
    summary?.availableToPromise &&
    !product?.inventory?.isInStock &&
    !isBreakableAndInStock(product) &&
    !prioritizeAlternative &&
    !productHasStatus('1', product?.inventory) &&
    !summary?.properties?.has(ProductPropertiesEnum.specialOrder) &&
    poDates?.length > 0
  ) {
    if (!!cartState?.orderHeader?.confirmedDeliveryDate) {
      return (
        dayjs
          .utc(summary?.poDates?.[0]?.scheduledArrivalDate)
          .isAfter(
            dayjs.utc(cartState?.orderHeader?.confirmedDeliveryDate),
            'day',
          ) === false
      );
    } else {
      return (
        dayjs
          .utc(summary?.poDates?.[0]?.scheduledArrivalDate)
          .isBefore(dayjs.utc(nextDeliveryDate?.deliveryDate), 'day') === true
      );
    }
  }

  return false;
};

export const enrichProductWithOrderableFlag = (
  product: Product,
  productsMap: Map<number, Product>,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
) => {
  product.orderable = canOrderProduct(
    product,
    productsMap,
    appStateForProduct,
    listDataForProduct,
  );
};

export const enrichProductWithHotkeyIds = (product: Product) => {
  product.hotkeyIds = findOriginalIds(product);
};

export const enrichProductWithSubstituteMessage = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  const alternative = product?.alternative;
  if (
    alternative?.substituteInfo?.substituteType === SUBSTITUTE_TYPE.mslSub &&
    alternative?.type === ProductAlternativeTypeEnum.substitute
  ) {
    product.productCardResolutionMsg = {
      primaryText: 'i18n.lists.productCardMsgs.mslSuggestedSub',
    };

    const alternativeProduct: Product = { ...alternative?.product };
    enrichProductWithWarningMessage(alternativeProduct, appStateForProduct);
    enrichProductWithAdditionalWarningText(
      alternativeProduct,
      appStateForProduct.platformType,
    );
    const alternativeWarningMsg = alternativeProduct?.productCardWarningMsg;
    if (
      alternativeWarningMsg?.display &&
      productHasStatus('0', alternative?.product?.inventory) &&
      alternative?.product?.inventory?.isInStockForSub === false
    ) {
      product.productCardResolutionMsg = {
        ...product.productCardResolutionMsg,
        secondaryText: alternativeWarningMsg?.primaryText,
        tertiaryText: [
          'i18n.lists.productCardMsgs.expectedInStock',
          'i18n.lists.productCardMsgs.inStock',
        ]?.includes(alternativeWarningMsg?.secondaryText)
          ? alternativeWarningMsg?.boldedText ===
            'i18n.productDetail.inventoryInfo.today'
            ? 'i18n.lists.productCardMsgs.expectInTodayPrefix'
            : 'i18n.lists.productCardMsgs.expectInDatePrefix'
          : alternativeWarningMsg?.secondaryText,
      };
      if (
        alternativeWarningMsg?.boldedText ===
        'i18n.productDetail.inventoryInfo.today'
      ) {
        product.productCardResolutionMsg.tertiaryTextDate =
          alternativeWarningMsg?.boldedText;
        product.productCardResolutionMsg.tertiaryTextDateStyle = {
          'font-weight': 'bold',
        };
      } else {
        product.productCardResolutionMsg.tertiaryTextDate =
          alternativeWarningMsg?.secondaryTextDate;
      }
    }
  } else if (alternative?.type === ProductAlternativeTypeEnum.replacement) {
    product.productCardResolutionMsg = {
      primaryText: 'i18n.lists.productCardMsgs.replacement',
    };
  } else if (alternative?.type === ProductAlternativeTypeEnum.substitute) {
    product.productCardResolutionMsg = {
      primaryText: 'i18n.lists.productCardMsgs.usfSuggestedSub',
    };
  }
};

export const enrichProductWithThumbnail = (product: Product) => {
  product.imageThumbnail = getFirstImageURL(
    product?.summary,
    ImageVariantEnum.Thumbnail,
  );
  product.orderedImages = getOrderImages(product?.summary);
};

export const enrichProductWithTags = (
  product: Product,
  isMslRestricted: boolean,
  isGuestUser: boolean,
) => {
  product.productTags = getDisplayTags(
    isMslRestricted,
    product.summary,
    product.contract,
    product?.customerPill,
    product.recentPurchase,
    isGuestUser,
  );
};

export const enrichProductWithHideQtyBoxes = (product: Product) => {
  if (shouldHideQuantityBoxes(product)) {
    product.hideQuantityBoxes = true;
  }
};

export const enrichProductWithOrderData = (
  product: Product,
  orderItemState: OrderItemState,
) => {
  const orderItem = orderItemState?.entities[product.productNumber];
  product.casesOrdered = orderItem?.unitsOrdered;
  product.eachesOrdered = orderItem?.eachesOrdered;
};

export const enrichProductWithMessages = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  enrichProductWithWarningMessage(product, appStateForProduct);
  enrichProductWithCesMessage(product, appStateForProduct.platformType);
  enrichProductWithJitMessage(product);
  enrichProductWithDirectMessage(
    product,
    appStateForProduct.directAllowReturnsFlag,
  );
  enrichProductWithAdditionalWarningText(
    product,
    appStateForProduct.platformType,
  );
};

export const enrichProductAlternativeWithMessages = (
  product: Product,
  directAllowReturns: boolean,
) => {
  enrichProductAlternativeWithCesMessage(product);
  enrichProductWithJitMessage(product);
  enrichProductWithDirectMessage(product, directAllowReturns);
};

export const enrichProductWithWarningMessage = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  product.productCardWarningMsg = buildAlertMessage(
    product,
    true,
    appStateForProduct.isMslRestricted,
    appStateForProduct.isOgRestricted,
    appStateForProduct.isMSLRestrictionOverrideY,
    appStateForProduct.isForceRegularOrderProduct,
  );
};

export const enrichProductWithCesMessage = (
  product: Product,
  platformType: PlatformType,
) => {
  if (product.summary.properties.has(ProductPropertiesEnum.ces)) {
    if (
      platformType === PlatformEnum.desktop ||
      platformType === PlatformEnum.tablet
    ) {
      product.vendorMsg = getCESMessageLong(product);
    } else {
      product.vendorMsg = getCESMessageShort(product);
    }
  }
};

export const enrichProductAlternativeWithCesMessage = (product: Product) => {
  if (product.summary.properties.has(ProductPropertiesEnum.ces)) {
    product.vendorMsg = getCESMessageShort(product);
  }
};

export const enrichProductWithJitMessage = (product: Product) => {
  if (product.summary.properties.has(ProductPropertiesEnum.jit)) {
    product.vendorMsg = getJITMessage(product);
  }
};

export const enrichProductWithDirectMessage = (
  product: Product,
  directAllowReturns: boolean,
) => {
  if (
    product.summary.properties.has(ProductPropertiesEnum.direct) &&
    productHasStatus('1', product.inventory)
  ) {
    product.vendorMsg = getDirectMessage(product, directAllowReturns);
  }
};

export const enrichProductWithAdditionalWarningText = (
  product: Product,
  platformType: PlatformType,
) => {
  if (
    productHasStatus('0', product.inventory) &&
    !product.inventory?.isInStock &&
    !product?.hideOutOfStock
  ) {
    if (product.summary.poDates?.length > 0) {
      if (platformType === PlatformEnum.mobile) {
        product.productCardWarningMsg.secondaryText =
          'i18n.lists.productCardMsgs.inStock';
      } else {
        product.productCardWarningMsg.secondaryText =
          'i18n.lists.productCardMsgs.expectedInStock';
      }

      if (
        dayjs(product.summary.poDates[0].scheduledArrivalDate).isSame(
          dayjs().utcOffset(0).startOf('date'),
          'day',
        )
      ) {
        product.productCardWarningMsg.boldedText =
          'i18n.productDetail.inventoryInfo.today';
      } else {
        product.productCardWarningMsg.secondaryTextDate =
          dayjs(product.summary.poDates[0].scheduledArrivalDate)
            .utc()
            .format('MM/DD') + '.';
      }
    }
  }
};

export const enrichProductWithDrawerMessage = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  product.drawerMsg = calculateDrawerMessage(product, appStateForProduct);
};

export const enrichProductWithQuickViewWarning = (
  product: Product,
  appStateForProduct: AppStateForProduct,
) => {
  product.quickViewWarning = calculateQuickViewWarning(
    product,
    appStateForProduct,
  );
};

export const mapProductPropertiesToAnalyticsTrackingProperties = (
  properties: Set<ProductPropertiesEnum>,
  additionalProperties: Set<ProductPropertiesEnum>,
) => {
  const allProperties = new Set<ProductPropertiesEnum>();
  if (properties?.size > 0) {
    properties.forEach(property => allProperties.add(property));
  }
  if (additionalProperties?.size > 0) {
    additionalProperties.forEach(property => allProperties.add(property));
  }

  const trackingAttributes = new Set<String>();
  Array.from(allProperties).forEach(property => {
    const analyticsProperty =
      PRODUCT_ATTRIBUTE_TRACK[ProductPropertiesEnum[property]];
    if (!!analyticsProperty) {
      trackingAttributes.add(analyticsProperty);
    }
  });
  return Array.from(trackingAttributes).join(',');
};

export const getAllListProductProperties = (
  productNumber: number,
  listDataForProduct: ListDataForProduct,
) => {
  const properties: Set<ProductPropertiesEnum> = new Set();
  if (
    hasRecentPurchase(listDataForProduct?.recentPurchaseDict[productNumber])
  ) {
    properties.add(ProductPropertiesEnum.recentlyPurchased);
  }
  const masterListItemProduct =
    listDataForProduct?.masterListItemProductMap[productNumber];
  if (masterListItemProduct) {
    properties.add(ProductPropertiesEnum.onMasterList);
  }
  const listItemProduct = listDataForProduct?.listItemProductMap[productNumber];
  if (listItemProduct) {
    properties.add(ProductPropertiesEnum.onOrderGuideOrOnShoppingList);
    if (listItemProduct.isOnList) {
      properties.add(ProductPropertiesEnum.onShoppingList);
    }
    if (listItemProduct.isOnOrderGuide) {
      properties.add(ProductPropertiesEnum.onOrderGuide);
    }
  }
  return properties;
};

export const mapProductPropertiesToTrackingProperties = (
  properties: Set<ProductPropertiesEnum>,
  promotionProperty: string,
) => {
  const trackingAttributes = new Set<String>();
  Array.from(properties).forEach(property => {
    const analyticsProperty =
      PRODUCT_ATTRIBUTE_TRACK[ProductPropertiesEnum[property]];
    if (!!analyticsProperty) {
      trackingAttributes.add(analyticsProperty);
    }
  });
  if (!!promotionProperty) {
    trackingAttributes.add(promotionProperty);
  }
  return Array.from(trackingAttributes).join(',');
};

export const findOriginalIds = (product: Product) => {
  let ret = [];
  const productIsOrderableAndNotSpecialOrder =
    product.orderable &&
    !product.summary.properties.has(ProductPropertiesEnum.specialOrder);
  if (!product?.notFound) {
    if (product.hasAlternative) {
      if (
        productHasStatus('0', product.inventory) &&
        !product.inventory?.isInStock
      ) {
        if (
          productIsOrderableAndNotSpecialOrder &&
          product.summary?.breakable
        ) {
          ret.push('OOS-cs-');
          ret.push('OOS-ea-');
        } else if (productIsOrderableAndNotSpecialOrder) {
          ret.push('OOS-cs-');
        }
      }
    } else {
      if (productIsOrderableAndNotSpecialOrder && product.summary?.breakable) {
        ret.push('cs-');
        ret.push('ea-');
      } else if (productIsOrderableAndNotSpecialOrder) {
        ret.push('cs-');
      }
    }
  }
  return ret;
};

export const shouldAddSupplierOutOfStock = (
  inventory: ProductInventory,
  alternative: ProductAlternative,
): boolean => {
  return (
    alternative.available &&
    alternative.required &&
    alternative.prioritizeAlternative &&
    isSupplierOutOfStock(inventory)
  );
};

export const shouldShowSplitCard = (
  product: Product,
  appStateForProduct: AppStateForProduct,
): boolean => {
  if (
    !appStateForProduct.isMSLRestrictionOverrideY &&
    appStateForProduct.isMslRestricted &&
    !product?.summary?.mslUnrestrict &&
    !product?.mslProduct?.isOrderableOnMsl &&
    !mslProductHasSecondaries(product?.mslProduct) &&
    !product?.summary?.properties?.has(ProductPropertiesEnum.discontinued)
  )
    return false;
  if (
    appStateForProduct.isOgRestricted &&
    !product?.summary?.properties?.has(ProductPropertiesEnum.onOrderGuide)
  )
    return false;

  return product?.alternative?.required && product?.alternative?.available;
};

export const enrichProductWithBetterBuy = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
  productsMap: Map<number, Product>,
  betterBuys: Dictionary<BetterBuysState>,
) => {
  const betterBuy = betterBuys[product.productNumber];
  if (
    shouldShowBetterBuy(
      betterBuy,
      appStateForProduct,
      listDataForProduct,
      productsMap,
    )
  ) {
    const productForBetterBuy = productsMap.get(
      betterBuy.betterBuyProductNumber,
    );
    let hotkeyIds: string[] = [];
    if (productForBetterBuy?.orderable) {
      hotkeyIds.push(HotKeys.betterBuys + '-' + HotKeys.case + '-');
    }
    if (productForBetterBuy?.summary?.breakable) {
      hotkeyIds.push(HotKeys.betterBuys + '-' + HotKeys.each + '-');
    }
    const betterBuyProduct: BetterBuyProduct = {
      savings: betterBuy.savings,
      annualSavings: betterBuy.annualSavings,
      savingsUom: betterBuy.savingsUom
        ? betterBuy.savingsUom.toUpperCase()
        : null,
      annualSavingsUom: betterBuy.annualSavingsUom,
      reason: betterBuy.reason,
      savingsPerCase:
        betterBuy.savingsPerCase != betterBuy.savings
          ? betterBuy.savingsPerCase
          : 0,
      product: {
        ...productForBetterBuy,
        hotkeyIds,
      },
    };
    product.betterBuy = betterBuyProduct;
  } else {
    product.betterBuy = undefined;
  }
};

export const shouldShowBetterBuy = (
  betterBuy: BetterBuysState,
  appStateForProduct: AppStateForProduct,
  listDataForProduct: ListDataForProduct,
  productsMap: Map<number, Product>,
): boolean => {
  if (!betterBuy) {
    return false;
  }
  const newProduct = productsMap.get(betterBuy.betterBuyProductNumber);
  if (appStateForProduct.isMslRestricted) {
    return (
      mslProductHasSecondaries(newProduct?.mslProduct) ||
      newProduct?.summary?.mslUnrestrict
    );
  } else if (appStateForProduct.isOgRestricted) {
    const listItemProduct: ListItemProduct =
      listDataForProduct.listItemProductMap[newProduct?.productNumber];
    return listItemProduct?.isOnOrderGuide;
  }
  return true;
};

export const calculateQuickViewWarning = (
  product: Product,
  appStateForProduct: AppStateForProduct,
): ProductQuickViewWarning => {
  let ret: ProductQuickViewWarning = {
    message: '',
    secondaryMessage: '',
    iconName: 'alert-circle-outline',
    isWarning: undefined,
  };

  if (
    !appStateForProduct.isMSLRestrictionOverrideY &&
    appStateForProduct.isMslRestricted &&
    !product?.mslProduct?.isOrderableOnMsl &&
    !mslProductHasSecondaries(product?.mslProduct) &&
    !product?.orderable
  ) {
    ret.isWarning = true;
    ret.message = 'Unable to Order.';
    ret.secondaryMessage = 'This product is not on your Master Shopping List.';
  } else if (
    appStateForProduct.isOgRestricted &&
    !product.summary.properties.has(ProductPropertiesEnum.onOrderGuide) &&
    !product?.orderable
  ) {
    ret.isWarning = true;
    ret.message = 'Unable to Order.';
    ret.secondaryMessage = 'This product is not on your Order Guide.';
  }

  return ret;
};

export const calculateDrawerMessage = (
  product: Product,
  appStateForProduct: AppStateForProduct,
): ProductDrawerMessage => {
  let ret: ProductDrawerMessage = {
    message: '',
    secondaryMessage: '',
    secondaryMessageDate: '',
    iconName: 'close-circle-outline',
    isWarning: undefined,
  };
  if (product?.notFound) {
    ret.message = 'i18n.productDetail.productNoLongerAvailable';
    ret.isWarning = true;
  } else if (
    calculateMslAlert(
      appStateForProduct.isMSLRestrictionOverrideY,
      appStateForProduct.isMslRestricted,
      product?.mslProduct?.isOrderableOnMsl,
      mslProductHasSecondaries(product?.mslProduct),
      product?.orderable,
      product?.summary?.mslUnrestrict,
      product?.summary?.properties?.has(ProductPropertiesEnum.discontinued),
    )
  ) {
    ret.isWarning = true;
    ret.message = 'Unable to Order.';
    ret.secondaryMessage = 'This product is not on your Master Shopping List.';
  } else if (
    appStateForProduct.isOgRestricted &&
    !product.summary.properties.has(ProductPropertiesEnum.onOrderGuide) &&
    !product.orderable
  ) {
    ret.isWarning = true;
    ret.message = 'Unable to Order.';
    ret.secondaryMessage = 'This product is not on your Order Guide.';
  } else if (
    !product?.hasAlternative &&
    product?.productCardWarningMsg?.display
  ) {
    ret.message = product?.productCardWarningMsg?.primaryText;
    ret.secondaryMessage = product?.productCardWarningMsg?.secondaryText;
    ret.secondaryMessageDate =
      product?.productCardWarningMsg?.secondaryTextDate;
    if (product?.productCardWarningMsg?.secondaryTextDate) {
      ret.secondaryMessageDate =
        product?.productCardWarningMsg?.secondaryTextDate;
    }
    if (product?.productCardWarningMsg?.boldedText) {
      ret.boldedText = product?.productCardWarningMsg.boldedText;
    }
    ret.isWarning = true;
  } else if (!!product?.vendorMsg) {
    ret.message = product?.vendorMsg;
    ret.iconName = 'information-circle-outline';
    ret.isWarning = false;
  } else if (!!product?.alternative?.product?.vendorMsg) {
    ret.message = product?.alternative?.product?.vendorMsg;
    ret.iconName = 'information-circle-outline';
    ret.isWarning = false;
  }
  return ret;
};

export const shouldHideQuantityBoxes = (product: Product): boolean => {
  return (
    product &&
    product.hasAlternative &&
    ((!product.inventory?.isInStock &&
      productIsInStatus(['3', '4'], product)) ||
      (product.summary?.properties?.has(
        ProductPropertiesEnum.supplierOutOfStock,
      ) &&
        !product.alternative?.prioritizeBaseProduct) ||
      product.summary?.properties?.has(ProductPropertiesEnum.dwo) ||
      product.summary?.properties?.has(ProductPropertiesEnum.discontinued))
  );
};

export const productsAreInState = (productMap: Map<number, Product>) => {
  let ret = true;
  for (const [key, value] of productMap) {
    if (!value) {
      ret = false;
      break;
    }
  }
  return ret;
};

export const productsAreInStateWithPricing = (
  productMap: Map<number, Product>,
) => {
  let ret = true;
  for (const [key, value] of productMap) {
    if (!value) {
      ret = false;
      break;
    } else if (value.pricing?.loading) {
      ret = false;
      break;
    }
  }
  return ret;
};

export const enrichWithShowHideWorksWellWith = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  isGuestUser: boolean,
) => {
  product.showWorksWellWith =
    !!product.orderable && // orderable
    product.summary.worksWellWith?.complement?.length > 0 && // has complements in workswellwith data
    !(
      product.hasAlternative && // stat 0 OOS with no alternative
      product.inventory?.productStatus === '0' &&
      !product.inventory?.isInStock
    ) &&
    appStateForProduct.isWorksWellWithEnabled &&
    !isGuestUser;
};

export const enrichProductWithPricePerPortion = (
  product: Product,
  customer: Customer,
) => {
  product.pricePerPortion = ProductPriceUtil.calculateCostBreakdown(
    product?.summary,
    customer,
    product?.pricing,
    product?.summary?.standardComparisonUOM,
    product?.summary?.standardComparisonValue,
  );
};

export const ignoreSupplierOutOfStock = (
  product: Product,
  forceRegularOrderProduct: boolean,
): boolean => {
  return (
    product?.summary?.properties?.has(
      ProductPropertiesEnum.supplierOutOfStock,
    ) &&
    !product?.summary?.properties?.has(ProductPropertiesEnum.direct) &&
    forceRegularOrderProduct
  );
};

export const isForceRegularOrderProduct = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  isMslRestricted: boolean,
): boolean => {
  let ret = ignoreSupplierOutOfStock(
    product,
    appStateForProduct.isForceRegularOrderProduct,
  );

  if (isMslRestricted === true && ret === true) {
    ret =
      isProductRestricted(product, appStateForProduct, isMslRestricted) ===
      false;
  }

  return ret;
};

export const isForceOutOfStockOrderProduct = (
  product: Product,
  hideInventoryOutOfStock: boolean,
): boolean => {
  return (
    product?.inventory?.isInStock &&
    productIsInStatus(['3', '4'], product) &&
    hideInventoryOutOfStock
  );
};

export const getGlStringForProduct = (
  productSummary: ProductSummary,
  glPimDictionary: Dictionary<GlPim>,
) => {
  const glInfo: GlDetails = {
    glCode: '',
    glDesc: '',
  };

  const productSummaryPim = glPimDictionary[productSummary?.productNumber];
  const groupCodePim = glPimDictionary[Number(productSummary?.groupCode)];
  const categoryCodePim = glPimDictionary[Number(productSummary?.categoryCode)];
  const classCodePim = glPimDictionary[Number(productSummary?.classCode)];

  if (productSummaryPim && productSummaryPim.pimType === 4) {
    glInfo.glCode = productSummaryPim?.glCode;
    glInfo.glDesc = productSummaryPim?.glDescription;
  } else if (groupCodePim && groupCodePim.pimType === 3) {
    glInfo.glCode = groupCodePim?.glCode;
    glInfo.glDesc = groupCodePim?.glDescription;
  } else if (categoryCodePim && categoryCodePim.pimType === 2) {
    glInfo.glCode = categoryCodePim?.glCode;
    glInfo.glDesc = categoryCodePim?.glDescription;
  } else if (classCodePim && classCodePim.pimType === 1) {
    glInfo.glCode = classCodePim?.glCode;
    glInfo.glDesc = classCodePim?.glDescription;
  }

  let glString = '';
  if (glInfo.glDesc && glInfo.glCode) {
    glString = glInfo.glCode.slice(0, 10) + ' - ' + glInfo.glDesc.slice(0, 25);
  } else if (glInfo.glCode) {
    glString = glInfo.glCode.slice(0, 10);
  } else {
    glString = glInfo.glDesc.slice(0, 25);
  }

  return !!glString ? glString : 'Unassigned';
};

export const getGlCodeForNonUsfProduct = (
  nonUsfProduct: NonUSFProductState,
  glPimDictionary: Dictionary<GlPim>,
) => {
  let glCode = '';

  const nonUsfProductPim = glPimDictionary[nonUsfProduct?.productNumber];
  const groupCodePim = glPimDictionary[Number(nonUsfProduct?.groupId)];
  const categoryCodePim = glPimDictionary[Number(nonUsfProduct?.category)];
  const classCodePim = glPimDictionary[Number(nonUsfProduct?.pimClass)];

  if (!!nonUsfProductPim && nonUsfProductPim.pimType === 4) {
    glCode = nonUsfProductPim?.glCode;
  } else if (!!groupCodePim && groupCodePim.pimType === 3) {
    glCode = groupCodePim?.glCode;
  } else if (!!categoryCodePim && categoryCodePim.pimType === 2) {
    glCode = categoryCodePim?.glCode;
  } else if (!!classCodePim && classCodePim.pimType === 1) {
    glCode = classCodePim?.glCode;
  }

  return !!glCode ? glCode : '';
};

export const handleStat34OutOfStock = (
  product: Product,
  appStateForProduct: AppStateForProduct,
  isMslRestricted: boolean,
) => {
  let ret = false;
  // if product is OOS stat 3/4 and OOS sub flag in CCA is ON, product is orderable
  if (
    isForceOutOfStockOrderProduct(
      product,
      appStateForProduct.isHideInventoryOutOfStockYellowModule,
    ) === true
  ) {
    ret = true;
  }
  if (isMslRestricted === true && ret === true) {
    // if the customer is restricted to MSL, the product has to be on a master list to stay orderable
    ret =
      isProductRestricted(product, appStateForProduct, isMslRestricted) ===
      false;
  }
  return ret;
};
