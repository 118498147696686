import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import {
  ExternalVendorState,
  allExternalVendors,
  allNonUSFProducts,
  getNonUSFProductSelections,
  getKeyword,
  getNonUSFSortOptions,
  NonUsfProductSortOptions,
  SortOptionsEnum,
} from '@usf/ngrx-product';
import {
  NonUSFProductViewModel,
  manageNonUsfProductsViewModel,
} from '../models/non-usf-products-view-model';
import { transformNonUsfProductToProduct } from '../transformers/non-usf-products-transformers';
import { PlatformEnum } from '@panamax/app-state';
import { calculateVSHeights } from './helpers/manage-non-usf-products-virtual-scroll-helper';
import { selectGlPimDictionary } from '@usf/ngrx-gl';
import { getGlCodeForNonUsfProduct } from '@shared/selectors/helpers/product-info.selectors.helper';

@Injectable({
  providedIn: 'root',
})
export class NonUsfProductViewModelSelectors {
  constructor() {}

  public getNonUSFProductViewModel = (platformType: PlatformEnum) => {
    return createSelector(
      allNonUSFProducts(),
      getNonUSFProductSelections(),
      allExternalVendors(),
      getKeyword(),
      getNonUSFSortOptions(),
      (productsMap, selections, vendors, keyword, sortOptions) => {
        let products: NonUSFProductViewModel[] = [];
        productsMap.forEach(product => {
          if (product !== undefined) {
            const vendorFound: ExternalVendorState = vendors.find(vendor => {
              return vendor.vendorId === product.vendorId;
            });
            products.push({
              ...product,
              isSelected:
                selections[product?.productNumber ?? 999999999999999] ?? false,
              vendorName:
                vendorFound !== undefined ? vendorFound.distributorName : '',
            });
          }
        });
        const totalNonUsfProducts = products.length;
        if (!!keyword) {
          const upperKeyWord = keyword.toUpperCase();
          products = products.filter(product => {
            return (
              product?.productDescLong
                .toUpperCase()
                .includes(upperKeyWord || '') ||
              product?.vendorName.toUpperCase().includes(upperKeyWord || '') ||
              product?.brand.toUpperCase().includes(upperKeyWord || '') ||
              product?.productNumber.toString().includes(upperKeyWord || '') ||
              product?.manufacturerProductNumber
                .toUpperCase()
                .includes(upperKeyWord || '')
            );
          });
        }
        const itemHeights = calculateVSHeights(products, platformType);
        products = this.sortNonUsfProducts(products, sortOptions);
        return {
          products,
          vendors,
          itemHeights,
          sortOptions,
          totalNonUsfProducts,
        } as manageNonUsfProductsViewModel;
      },
    );
  };

  public sortNonUsfProducts = (
    products: NonUSFProductViewModel[],
    sortOptions: NonUsfProductSortOptions,
  ): NonUSFProductViewModel[] => {
    const sortedProducts = products.sort((a, b) => {
      const aSortValue = this.getProductSortValue(
        a,
        sortOptions.selectedHeader,
      );
      const bSortValue = this.getProductSortValue(
        b,
        sortOptions.selectedHeader,
      );
      const sortDirection = this.getSortDirection(sortOptions);
      if (!aSortValue && !!bSortValue) {
        return sortDirection === SortOptionsEnum.desc ? 1 : -1;
      }
      if (!!aSortValue && !bSortValue) {
        return sortDirection === SortOptionsEnum.desc ? -1 : 1;
      }
      if (aSortValue === bSortValue || (!aSortValue && !bSortValue)) {
        return a?.productNumber > b?.productNumber ? -1 : 1;
      }
      if (sortDirection === SortOptionsEnum.desc) {
        return aSortValue > bSortValue ? -1 : 1;
      } else {
        return aSortValue > bSortValue ? 1 : -1;
      }
    });
    return sortedProducts;
  };

  public getProductSortValue = (
    product: NonUSFProductViewModel,
    selectedHeader: string,
  ) => {
    if (selectedHeader === SortOptionsEnum.productName) {
      return product.productDescLong?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.packSize) {
      return product.salesPackSize?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.manufacturerNumber) {
      return product.manufacturerProductNumber?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.brand) {
      return product.brand?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.vendor) {
      return product.vendorName?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.pricing) {
      return product.price;
    } else {
      return product.productNumber;
    }
  };

  public getSortDirection = (sortOptions: NonUsfProductSortOptions) => {
    return sortOptions[sortOptions.selectedHeader].sortDirection;
  };

  public getSelectionsArray = () => {
    return createSelector(getNonUSFProductSelections(), selections => {
      return Object.keys(selections).map(value => {
        if (selections[value]) {
          return Number(value).valueOf();
        }
      });
    });
  };

  public getNonUSfProductsBasedOnId = (ids: number[]) => {
    return createSelector(allNonUSFProducts(), productMap => {
      return productMap
        .filter(product => ids.includes(product.productNumber))
        .sort((a, b) => a.productDescLong.localeCompare(b.productDescLong));
    });
  };

  public getNonUSfProductsByIdForDownload = (ids: number[]) => {
    return createSelector(
      allNonUSFProducts(),
      allExternalVendors(),
      selectGlPimDictionary,
      getNonUSFSortOptions(),
      (productsMap, vendors, glPimDictionary, sortOptions) => {
        let products: NonUSFProductViewModel[] = [];
        productsMap
          .filter(product => {
            return ids.includes(product.productNumber);
          })
          .forEach(product => {
            if (!!product) {
              const vendorFound: ExternalVendorState = vendors.find(vendor => {
                return vendor.vendorId === product.vendorId;
              });
              products.push({
                ...product,
                isSelected: false,
                vendorName: vendorFound?.distributorName ?? '',
                glCode: getGlCodeForNonUsfProduct(product, glPimDictionary),
              });
            }
          });

        products = this.sortNonUsfProducts(products, sortOptions);
        return products;
      },
    );
  };

  public getFakeProductsFromNonUSfProductsBasedOnId = (ids: number[]) => {
    return createSelector(allNonUSFProducts(), productMap => {
      const productsFound = productMap
        .filter(product => ids.includes(product.productNumber))
        .map(prod =>
          transformNonUsfProductToProduct(prod as NonUSFProductViewModel),
        );

      const shortIDList = ids.filter(
        id =>
          productMap.find(product => product.productNumber === id) ===
          undefined,
      );

      const productsNotFound = shortIDList.map(value =>
        transformNonUsfProductToProduct({
          divisionNumber: productsFound[0].summary.divisionNumber ?? 0,
          customerNumber: 0,
          departmentNumber: 0,
          productDescLong: '',
          productNumber: value,
          isSelected: false,
          vendorName: undefined,
        }),
      );

      return [...productsFound, ...productsNotFound];
    });
  };

  public getAllNonUSFProductsAndConvertToFakeProducts = () => {
    return createSelector(
      allNonUSFProducts(),
      allExternalVendors(),
      (productMap, vendors) => {
        const products: NonUSFProductViewModel[] = [];
        productMap.forEach(product => {
          if (!!product) {
            const vendorFound: ExternalVendorState = vendors.find(vendor => {
              return vendor.vendorId === product.vendorId;
            });
            products.push({
              ...product,
              isSelected: false,
              vendorName: vendorFound?.distributorName ?? '',
            });
          }
        });
        return products.map(prod => transformNonUsfProductToProduct(prod));
      },
    );
  };

  public getNonUsfProductAnalyticsData = () => {
    return createSelector(allNonUSFProducts(), nonUsfProducts => {
      let productsForTracking = [];
      nonUsfProducts?.forEach(nonUsfProduct => {
        productsForTracking.push({
          divisionApn:
            nonUsfProduct.divisionNumber + '-' + nonUsfProduct.productNumber,
          nonUsfProductName: nonUsfProduct.productDescLong,
          nonUsfProductPrice: nonUsfProduct.price,
          nonUsfProductView: '1',
        });
      });
      return productsForTracking;
    });
  };
}
