import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import {
  PageInfoService,
  PanAppState,
  isTokenExpired,
  UsfTokenService,
  TokenAuthMode,
  LOCAL_STORAGE_KEYS,
} from '@panamax/app-state';
import { LoginDataService } from 'src/app/login/services';
import { PATHS } from '../../constants/paths';
import { ViewDidLeave } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { LoginActions } from '@app/login/store/actions/action-types';

@Injectable({
  providedIn: 'root',
})
export class AuthConnectGuardService implements CanActivate, ViewDidLeave {
  constructor(
    private pageInfo: PageInfoService,
    private location: Location,
    private loginDataService: LoginDataService,
    private panAppState: PanAppState,
    private tokenService: UsfTokenService,
    private router: Router,
  ) {}

  b2cFeatureFlag$: Subscription;

  canActivate(
    route: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
  ): Promise<boolean> {
    console.log('[Auth Guard] inspecting route', state.url, route.params);

    if (
      this.pageInfo.getUrlPath().indexOf('b2c') < 0 &&
      // in mobile, getUrlPath returns / on other calls i.e. native deep links
      this.pageInfo.getUrlPath() !== '/' &&
      this.pageInfo.getUrlPath() !== PATHS.LOGIN &&
      this.pageInfo.getUrlPath() !== PATHS.LOGOUT &&
      this.pageInfo.getUrlPath() !== PATHS.LOGIN_ERROR &&
      !this.pageInfo.getUrlPath().endsWith('/404')
    ) {
      console.log('[Auth Guard] saving navigation', state.url, route.params);

      this.pageInfo.saveQueryParamsToStorage(
        LOCAL_STORAGE_KEYS.params,
        route.queryParams,
        state.url.split('?')[0],
      );
    }
    console.log('[Auth Guard] guarding route ' + state.url);
    return this.tokenService
      .authorize(TokenAuthMode.Local, { disableErrorRedirect: true })
      .then(
        async _ => {
          return true;
        },
        async e => {
          console.log('[Auth Guard] Authorization rejected', e);
          if (e?.status === 503) {
            console.log(
              '[Auth Guard] Navigating to scheduled maintenance page',
            );
            await this.tokenService.navigateToScheduledMaintenance();
            return false;
          }
          this.router.navigate([PATHS.LOGIN]);
          return false;
        },
      );
  }

  hasR4Access(token: string): boolean {
    const decoded = this.decodeToken(token);
    if (!decoded) {
      return false;
    } else {
      return decoded['usf-claims'].r4Redirect === 'Y';
    }
  }

  decodeToken(token = '') {
    if (!token) {
      return false;
    }
    return JSON.parse(atob(token.split('.')[1]));
  }

  hasProductDetailPath() {
    const params = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.params));
    if (
      (!!params && params?.returnUrl?.includes('products')) ||
      this.location.path().includes('products')
    ) {
      // delete params['returnUrl'];
      // localStorage.setItem('ecomr4-params', JSON.stringify(params));
      return true;
    } else {
      return false;
    }
  }

  async isAuthenticated(token: string): Promise<boolean> {
    const savedToken = this.getSavedAccessToken();
    if (!isTokenExpired(savedToken)) {
      return true;
    } else {
      try {
        console.log('[Auth Guard] Attempting USF token refresh');
        let refreshResult = await this.loginDataService
          .refreshAuthToken()
          .toPromise();
        if (refreshResult && refreshResult.accessToken) {
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.token,
            JSON.stringify(refreshResult),
          );
          this.panAppState.persistToken(refreshResult);
        }
        return !isTokenExpired(this.getSavedAccessToken());
      } catch (e) {
        //TODO: distinguish between error types here
        console.error('[Auth Guard] Error refreshing token', e);
        if (e.status && e.status == '400') {
          LoginActions.logout();
        }
      }
      return false;
    }
  }

  getSavedAccessToken() {
    let token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.token));
    return token ? token.accessToken : { accessToken: '' };
  }

  isAuthenticatedSousUser(token: string) {
    return (
      !isTokenExpired(token) &&
      this.isSousUser(token) &&
      this.hasProductDetailPath()
    );
  }

  isSousUser(token: string): boolean {
    return this.isAuthenticated(token) && !this.hasR4Access(token);
  }

  ionViewDidLeave(): void {
    if (this.b2cFeatureFlag$) {
      this.b2cFeatureFlag$.unsubscribe();
    }
  }
}
